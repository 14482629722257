html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #131313;
  color: white;
  flex: 1;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root > div {
  height: 33%;
}

.recharts-tooltip-wrapper,
.tooltip {
  background-color: #fff;
  color: #000;
}
